import type { EventData, SocketQuestData } from "@/types";

const useQuestsSocket = () => {
	const { open, isOpen } = useTaoModals();
	const { add, remove } = useSockets();
	const { handleJoinQuest } = useGameQuest();

	const { data: questPageData, refresh: refreshQuestPageData } = useGetPageQuestData({ immediate: false });

	const { data: questData } = useQuestData({ immediate: false });

	const isProgressNotifyOnce = ref(false);

	const updateTaskState = (task: SocketQuestData, type: "complete" | "progress" | "deposit") => {
		const currentTask = questPageData.value?.playerData?.days?.[task.dayIndex]?.tasks?.[task.taskIndex];
		const questInfoTask = questData.value?.dayInfo?.tasks?.[task.taskIndex];

		if (currentTask) {
			if (type === "complete") {
				currentTask.isCompleted = true;
			}
			if (type === "progress") {
				currentTask.progress = task.progress;
				currentTask.repeat = task.repeat;
			}
			if (task.executionTimeData) {
				currentTask.executionTimeData = task.executionTimeData;
			}
		}

		if (questInfoTask) {
			if (type === "complete") {
				questInfoTask.isCompleted = true;
			}

			if (type === "progress") {
				questInfoTask.progress = task.progress;
				questInfoTask.repeat = task.repeat;
			}

			if (task.executionTimeData && typeof task.executionTimeData === "string") {
				questInfoTask.executionTimeData = task.executionTimeData;
			}
		}
	};

	add("questPoints", ({ action, data }: EventData<SocketQuestData>) => {
		if (action === "taskCompleted") {
			updateTaskState(data, "complete");
			isProgressNotifyOnce.value = false;

			if (isOpen("LazyOModalQuestGetStarted")) {
				open("LazyOModalQuestGetStarted", { nextMsg: data });
			}

			if (!isOpen("LazyOModalQuestGetStarted")) {
				open("LazyOModalQuestTaskCompleted", { task: data });
			}

			setTimeout(() => {
				refreshQuestPageData();
			}, 3000);
		}

		if (action === "taskProgress") {
			updateTaskState(data, "progress");
		}

		if (action === "finished") {
			if (questData.value?.questInfo?.type === "season") {
				open("LazyOModalChallengeSeasonWinner", { uncollectedPrize: data });
				return;
			}

			open("LazyOModalQuestWinnerNotification", { uncollectedPrize: data });
		}

		if (action === "availableByDeposit") {
			const activeQuestId = questData.value?.questInfo?.activeQuestId;

			if (activeQuestId) {
				handleJoinQuest(activeQuestId, true);
			}
		}
	});

	onBeforeUnmount(() => {
		remove("questPoints");
	});
};

export default useQuestsSocket;
